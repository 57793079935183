import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { getAuth, signOut } from "firebase/auth";
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';


export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);

  };

  const handleCloseaccount = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      navigate("/");
      window.location.reload(false);
      localStorage.clear();
      sessionStorage.clear()
    }).catch((error) => {
      console.log(error)
    });
  };

  const getemail = localStorage.getItem("email")
  // const username = getemail.split('@')[0].toLowerCase().replace(/[0-9]+$/, '');
  // if (getemail && username) {
  //   console.log("user is not login ")
  // } else {
  //   console.log("user is  login ")
  // }
  const isMounted = useRef(false);
  const [userData, setUserData] = useState([])
  // useEffect(() => {
  //   const userid = localStorage.getItem('uid')
  //   const endpoint = `${API_ENDPOINTS.userDataprofileGET}?userid=${userid}`;
  //   fetch(`${BASE_URL}${endpoint}`)
  //     .then(response => response.json())
  //     .then((data) => {
  //       const user = data.profile;
        
  //       setProfileimg(user.profile_image)
  //       setUserData(user.profile_image);
  //     })
  //     .catch(error => console.error(error));
  // }, []);

  // const setProfileimg = (userData.map((img) => (img.profile_image)))
  // const [Profileimg, setProfileimg] = useState('');

  // const createusername = localStorage.getItem("email")
  // localStorage.setItem("profileimg", setProfileimg)
  // localStorage.setItem("username", (userData.map((img) => (img.username))))

  // const provider = localStorage.getItem("provider")

  // username and img fetch from api
  const [userName, setUsername] = useState('');
  const [userImg, setImg] = useState('');
  useEffect(() => {
    const userid = localStorage.getItem('uid');
    const endpoint = `${API_ENDPOINTS.userDataprofileGET}?userid=${userid}`;

    fetch(`${BASE_URL}${endpoint}`)
      .then(response => response.json())
      .then((data) => {
        const user = data.profile;
        if (user) {
          setUsername(user.username);
          const profileImage = user.profile_image ? user.profile_image : "https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg";
          setImg(profileImage); // Set the image based on the condition
      
          // setImg(user.profile_image);
          localStorage.setItem('setprofileimg',user.profile_image)
        }
      })
      .catch(error => console.error(error));
  }, []);
  
  const [username ,setUserName] = useState('')
  const getemailid = localStorage.getItem("email")
  useEffect(()=>{
    if (getemailid == null) {
      console.log("user not login")
    } else {
      const createusername = getemailid.split('@');
      setUserName(createusername[0]);
    }
  },[])
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >

     
        {/* <Avatar src={(provider === "google.com") ? (localStorage.getItem('profileimg')) : "https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg"} alt="photoURL" /> */}
        <Avatar
           src={ (userImg==="")? "https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg": userImg} 
           alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" style={{ textTransform: "capitalize" }} noWrap>
          {(userName === "username") ? username : userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {getemail}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          {/* {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))} */}
          {/* <NavLink  style={{ textDecoration: "none", color: "black" }} to="/dashboard/app"> <MenuItem >Home</MenuItem></NavLink> */}
          <NavLink style={{ textDecoration: "none", color: "black" }} to="/profile"> <MenuItem >Profile</MenuItem></NavLink>
          {/* <MenuItem >Setting</MenuItem> */}
          <MenuItem onClick={handleCloseaccount} sx={{ m: 0, marginLeft:'inherit'}}>
          Logout
        </MenuItem>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
       
      </Popover>
    </>
  );
}
