export const BASE_URL = 'https://app.surveyslack.com/api/surveytask/api/v1';
export const API_ENDPOINTS = {
  userDataPOST: '/user.php',
  userDataGET : '/getuser.php',
  userDataprofileGET: '/userprofile.php',
  sendEmail:'/sendemail.php',
  answerImagePOST:'/imgupload.php',
  surveyListGET : '/getsurveylist.php',
  questionListGET  : '/getquestion.php',
  questionCreatePOST : '/surveyquestionary.php',
  profileDataUpdate : '/userprofile.php',//   For profile data post, Particular id use and that id data edit, delete and get
  surveyEdit : '/surveylist.php',
  getsurveyresponse: '/getsurveyresponse.php',
  saveRes: '/surveyresponse.php',
  responderdata: '/surveyresponder.php',
  Aisurvey: '/AIsurvey.php',
  QuestionBranching : '/QuestionBranchingRules.php'
  // Add more endpoints as needed
};


// how to use this in other file -
// Ex:-
// import React, { useEffect, useState } from 'react';
// import { BASE_URL, API_ENDPOINTS } from './config'; // Adjust the import path as needed

// function MyComponent() {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     // Define the API endpoint you want to call
//     const endpoint = API_ENDPOINTS.getUsers;

//     // Make the API call
//     fetch(`${BASE_URL}${endpoint}`)
//       .then((response) => response.json())
//       .then((responseData) => {
//         setData(responseData);
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   return (
//     <div>
//       {/* Render your data */}
//       <ul>
//         {data.map((item) => (
//           <li key={item.id}>{item.name}</li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default MyComponent;
