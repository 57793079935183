import PropTypes from 'prop-types';
import { useEffect,useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
// import { increaseCount } from '../../../Redux/actions';
import account from '../../../_mock/account';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/logo';
import logoWithText from '../../../img/SurveySlack-Full-Logo-With-Text.png'
import Scrollbar from '../../../components/scrollbar';
import EmailModal from '../../../components/Popup-folder/EmailModal';
import NavSection from '../../../components/nav-section/NavSection';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config'; 
import navConfig from './config';

// import navConfigTwo from './configCreatesurvey';

const NAV_WIDTH = 280;

const provider = localStorage.getItem("provider")
const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// const getemailid = localStorage.getItem("email")
// const createusername = getemailid.split('@');
// const username = createusername[0];


export default function Nav({ openNav, onCloseNav }) {
  const count = useSelector(state => state.count);
    const setName = useSelector((state) => state.userData)
  const [userName, setUsername] = useState('');
  const [userImg, setImg] = useState('');
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [username ,setUserName] = useState('')
  const getemailid = localStorage.getItem("email")
  useEffect(()=>{
    if (getemailid == null) {
      console.log("user not login")
    } else {
      const createusername = getemailid.split('@');
      setUserName(createusername[0]);
    }
  },[])



  useEffect(() => {
    const userid = localStorage.getItem('uid');
    const endpoint = `${API_ENDPOINTS.userDataprofileGET}?userid=${userid}`;

    fetch(`${BASE_URL}${endpoint}`)
      .then(response => response.json())
      .then((data) => {
        const user = data.profile;
        if (user) {
          setUsername(user.username);
          const profileImage = user.profile_image ? user.profile_image : fallbackImage;
          setImg(profileImage);
          localStorage.setItem('setprofileimg',profileImage)
        }
      })
      .catch(error => console.error(error));
  }, [count]);

  // console.log(userName)
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  // const showNavSection = window.location.hash.includes('surveycreatelayout');
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleEmailButtonClick = () => {
    setEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
  };

  const fallbackImage = "https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg";
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' ,margin:'5px'}}>
        {/* <Logo /> */}
        <img src={logoWithText} alt="altlogo" />
      </Box>

      <Box sx={{  mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar
              src={(userImg==="")? "https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg": userImg}  
              alt="photoURL"
              onError={(e) => {
                console.error("Error loading image:", e);
              }}
            />
            {/* <Avatar
              src={(provider === "google.com") ? localStorage.getItem('setprofileimg') : fallbackImage}
              alt="photoURL"
              onError={(e) => {
                console.error("Error loading image:", e);
              }}
            /> */}
            <Box sx={{ ml: 2 }}>
            {setName ===""&&<Typography variant="subtitle2" sx={{ color: 'text.primary', textTransform: 'capitalize' }}>
                {(userName === "username") ? username : userName}
              </Typography>}
              <Typography variant="subtitle2" sx={{ color: 'text.primary', textTransform: 'capitalize' }}>
                {setName}
              </Typography>
              {/* main sidebar */}
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />
      {/* { <NavSection data={navConfigTwo} />} */}

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
      <Button
          style={{ width: '100%' }}
          component="label"
          className='bgButton'
          startIcon={<FontAwesomeIcon icon={faHeadset} />}
          onClick={handleEmailButtonClick}  // Open modal on click
        >
          Need Support?
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
    {/* {`nme ${setid}`} */}
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
          
        >
          {renderContent}
        </Drawer>
      )}
       <EmailModal open={isEmailModalOpen} handleClose={handleCloseEmailModal} />
    </Box>
  );
}
